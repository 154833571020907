import { Injectable } from '@angular/core'
import { combineLatest, Observable } from 'rxjs'
import { select, Store } from '@ngrx/store'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AAAStore } from '../../store/root-reducer'
import { AuthState } from '../../modules/auth/auth.reducer'
import { AAACallStatus } from '../../modules/dashboard/calls.types'
import { selectActiveCallStatus } from '../../modules/dashboard/calls-statuses/call-status.selectors'
import { ConfigService } from '../../modules/config/config.service'
import { AuthSecurityWrapperService } from '../../modules/auth/auth-security-wrapper/auth-security-wrapper.service'
import { selectUserSessionId } from '../../modules/session/session.selectors'
import { selectAuthState } from '../../modules/auth/auth.selectors'
import * as xhrUtils from './xhr.utils'

@Injectable({
  providedIn: 'root',
})
export class XHRService {
  public headers: xhrUtils.Headers

  constructor(
    private store$: Store<AAAStore>,
    private configService: ConfigService,
    private authSecurityWrapperService: AuthSecurityWrapperService,
  ) {
    this.headerData$.subscribe(([auth, call, userSessionId]) => {
      this.headers = xhrUtils.createHeaders({ auth, call, userSessionId, clientId: this.configService.getConfig().clientId })
    })
  }

  headerData$: Observable<[AuthState, AAACallStatus, string]> = combineLatest([
    this.store$.pipe(select(selectAuthState)),
    this.store$.pipe(select(selectActiveCallStatus)),
    this.store$.pipe(select(selectUserSessionId)),
  ])

  async clubRequest<T>(args: xhrUtils.RequestArgs, options: xhrUtils.RequestOptions = {}) {
    if (typeof args === 'string') {
      return this.authRequest<T>(
        {
          url: args,
          headers: this.headers.club,
        },
        options
      )
    }
    return this.authRequest<T>(
      {
        ...args,
        headers: this.headers.club,
      },
      options
    )
  }

  async authRequest<T>(args: xhrUtils.RequestArgs, options: xhrUtils.RequestOptions = {}) {
    if (typeof args === 'string') {
      return this.request<T>(
        {
          url: args,
          headers: {
            ...(options.overrideAuthHeader
              ? { authorization: options.overrideAuthHeader }
              : this.headers.auth),
          },
        },
        options
      )
    }

    return this.request<T>(
      {
        ...args,
        headers: {
          ...args.headers,
          ...(options.overrideAuthHeader
            ? { authorization: options.overrideAuthHeader }
            : this.headers.auth),
        },
      },
      options
    )
  }

  async request<T>(
    args: xhrUtils.RequestArgs,
    options: xhrUtils.RequestOptions = {}
  ): Promise<T> {
    let response: AxiosResponse<T>
    let requestObject: AxiosRequestConfig
    const { useCustomAAAheaders } = options

    const requestConfig = xhrUtils.createRequestConfig({
      options,
      baseURL: this.configService.getConfig().baseURL
    })

    const requestUrl = xhrUtils.constructUrl(args, this.configService.getConfig().additionalCookieDomain)
    const headers =
      useCustomAAAheaders === undefined || useCustomAAAheaders
        ? this.headers.aaa
        : {}

    if (typeof args === 'string') {
      // @ts-ignore
      requestObject = {
        ...requestConfig,
        url: requestUrl,
        headers: { ...headers },
      }
    } else {
      // @ts-ignore
      requestObject = {
        ...requestConfig,
        ...args,
        url: requestUrl,
        headers: {
          ...headers,
          ...(args.headers as any),
        },
      }
    }

    try {
      response = await axios.request(requestObject)
      if (response.status >= 200 && response.status < 300) {
        const { url } = args as AxiosRequestConfig

        xhrUtils.resetURLTimeout(url)

        return response.data
      } else if (
        xhrUtils.canRetryRequest(requestObject.method) &&
        response.status === 408
      ) {
        return xhrUtils.handleRequestTimeout(this, args as AxiosRequestConfig, options)
      }

      throw response
    } catch (error) {
      if (error?.response?.status === 401) {
        this.authSecurityWrapperService.forceLogout(error?.response?.data?.error_description)
      }
      if (requestConfig.retryAll && xhrUtils.canRetryRequest(requestObject.method)) {
        if (error?.code === 'ECONNABORTED') {
          return xhrUtils.handleRequestTimeout(this, args as AxiosRequestConfig, options)
        } else if (error?.response?.status !== 401) {
          return xhrUtils.handleRequestRetry(this, options)(args as AxiosRequestConfig) as Promise<T>
        }
      }
      throw error
    }
  }
}
