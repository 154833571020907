import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { DestinationType, IndexedCollection } from '../../../shared/types'
import { AARData, MAKES_FIELD, RATING_SUMMARY_FIELD, SERVICES_FIELD, TowDestinationResponse } from './aar.types'
import { setNoMakesFacilities } from './aar.actions'
import { XHRService } from '../../../shared/services/xhr.service'
import { indexCollection } from '../../../shared/utils'
import { TowDestinationResponseSchema } from './aar.model'
import { RapService } from '../../rap/rap.service'
import { EvStationService } from './evstation.service';
import { AARDataAdapter } from "../../../shared/adapters/AARDataAdapter";

@Injectable({
  providedIn: 'root',
})
export class AarService {
  private retries = 0

  constructor(
    private xhr: XHRService,
    private store$: Store,
    private rapService: RapService,
    private evStationService: EvStationService,
    private adapter: AARDataAdapter,
  ) { }

  async getAARs({
    latitude,
    longitude,
    make,
    radius,
    limit,
    country,
    type,
  }: {
    latitude,
    longitude,
    make,
    radius?,
    limit?,
    country?,
    type?,
  }, destinationType: DestinationType): Promise<IndexedCollection<AARData>> {
    switch (destinationType) {
      case DestinationType.TOW_DESTINATION:
        return this.rapService.getAarsFromTowDestination({
          latitude,
          longitude,
          make,
          country,
          type
        })

      case DestinationType.EV_STATION:
        return this.evStationService.search(latitude, longitude, this.getEvSearchLimit(limit), radius)

      case DestinationType.AAR:
      default:
        return this.search(latitude, longitude, make, limit)

    }
  }

  private async search(latitude, longitude, make, limit) {
    try {
      const response = await this.xhr.authRequest<TowDestinationResponse>({
        url: 'bff/tow-destinations/aars',
        params: {
          requireResult: true,
          latitude,
          longitude,
          make,
          fields: `${MAKES_FIELD},${SERVICES_FIELD},${RATING_SUMMARY_FIELD}`,
          ...(limit ? { limit } : {}),
        },
      })
      await TowDestinationResponseSchema.validate(response)
      const aars = response.result.map(this.adapter.adaptTowDestination)

      if (!aars?.length && this.retries <= 0) {
        this.retries++
        this.dispatchNoMakesFacilities()
        return this.getAARs({
          latitude,
          longitude,
          make: undefined,
        }, DestinationType.AAR)
      }
      this.retries = 0

      return indexCollection<AARData, 'id'>(aars, 'id')
    } catch (error) {
      throw error
    }
  }

  private getEvSearchLimit(limit) {
    return this.rapService.isRapUser() ? this.rapService.getLimitWithinRadius() : limit
  }

  private dispatchNoMakesFacilities() {
    this.store$.dispatch(
      setNoMakesFacilities({
        payload: {
          active: true,
        },
      })
    )
  }
}
