import { XHRService } from '../../shared/services/xhr.service'
import { Injectable } from '@angular/core'
import {
  BatteryQuotePayload,
  BatteryQuotesResponse,
  BatteryQuote,
} from './quotes.types'
import { IndexedCollection } from '../../shared/types'
import { generateVehicleSlug } from '../../shared/utils/generateVehicleSlug'
import { TaggingService } from '../tagging/tagging.service'
import events from '../tagging/events'
import { AdobeEventTypes } from '../tagging/tagging.types'
import { AdobeEventService } from '../tagging/adobe/event-adobe.service'
import { BatteryQuotesResponseSchema } from './quotes.model'

@Injectable({
  providedIn: 'root',
})
export class QuotesService {
  constructor(
    private xhr: XHRService,
    private tagging: TaggingService,
    private adobeEventService: AdobeEventService,
  ) { }

  checkNotApplicableBatteryQuotes(quotes: BatteryQuote[]) {
    let failure = false;
    for (let index = 0; index < quotes.length; index++) {
      const element = quotes[index];
      if (!element?.memberPrice || element?.memberPrice === 'N/A') {
        failure = true
      }
    }

    return failure;
  }

  tagBatteryQuotes(quotePayload: BatteryQuotePayload, batteryQuotes: BatteryQuote[]) {
    const failedQuotes = this.checkNotApplicableBatteryQuotes(batteryQuotes)
    if (failedQuotes) {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.SYSTEM,
        eventValue: events.dashboard.BATTERY_QUOTES_NO_PRICE
      })

      this.tagging.setAutomatedEvent(
        events.dashboard.BATTERY_QUOTES_NO_PRICE,
        events.dashboard.SERVICE_TRACKING_PAGE_TYPE,
        null,
        {
          vehicleYear: quotePayload.vehicle.year,
          vehicleMake: quotePayload.vehicle.make,
          vehicleModel: quotePayload.vehicle.model,
          zipCode: quotePayload.postalCode
        }
      )
    } else {
      this.adobeEventService.sendEvent({
        eventName: AdobeEventTypes.SYSTEM,
        eventValue: events.dashboard.BATTERY_QUOTES_SUCCESS
      })

      this.tagging.setAutomatedEvent(
        events.dashboard.BATTERY_QUOTES_SUCCESS,
        events.dashboard.SERVICE_TRACKING_PAGE_TYPE
      )
    }
  }

  async getBatteryQuotes(
    data: BatteryQuotePayload,
    club: string
  ): Promise<IndexedCollection<Array<BatteryQuote>>> {
    try {
      const response = await this.xhr.authRequest<BatteryQuotesResponse>(
        {
          url: `bff/club/${club}/battery-quotes`,
          method: 'POST',
          data,
        },
        { retryAll: false }
      )
      await BatteryQuotesResponseSchema.validate(response)
      const quotes = response.batteryQuotes
      if (!quotes?.length) {
        throw new Error('No battery quote was found.')
      }

      const id = generateVehicleSlug(
        {
          year: data.vehicle.year,
          make: data.vehicle.make,
          model: data.vehicle.model,
        },
        data.postalCode
      )

      this.tagBatteryQuotes(data, quotes);

      return {
        [id]: quotes,
      }
    } catch(error) {
      console.log('No battery quote was found.', data)
      throw new Error(error)
    }
  }
}
