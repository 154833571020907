import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ev-station-notes',
  templateUrl: './station-notes.component.html',
  styleUrls: ['./station-notes.component.scss']
})
export class EvStationNotesComponent implements OnInit {
  @Input() operationDayNotes: string
  @Input() lastConfirmedDate: Date

  constructor() { }

  ngOnInit(): void {
  }

}
