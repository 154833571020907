import { createAction, props } from '@ngrx/store'
import { IndexedCollection } from '../../shared/types'
import { createAsyncConstants } from '../../shared/utils'
import { BatteryQuotePayload, BatteryQuote } from './quotes.types'

export const BATTERY_QUOTES = createAsyncConstants('BATTERY_QUOTES')
export const BATTERY_QUOTES_NOTIFIED = 'BATTERY_QUOTES_NOTIFIED'

export const batteryQuoteSuccess = createAction(
  BATTERY_QUOTES.SUCCESS,
  props<{ payload: IndexedCollection<Array<BatteryQuote>> }>()
)

export const batteryQuoteFailure = createAction(BATTERY_QUOTES.FAILURE)

export const batteryQuoteRequest = createAction(
  BATTERY_QUOTES.REQUEST,
  props<{ payload: BatteryQuotePayload }>()
)

export const batteryQuoteNotify = createAction(
  BATTERY_QUOTES_NOTIFIED,
  props<{
    payload: {
      [id: string]: boolean
    }
  }>()
)
