<div class="dialog">
  <div mat-dialog-title>
    <div class="main-title-container">
      <app-img src="assets/drrweb-lib/images/aaabattery.JPG" class="icon" alt="Battery Quotes" i18n-alt></app-img>
      <h1 i18n> Battery Quotes </h1>
    </div>
    <div class="main-subtitle-container">
      <p i18n> 3-Year Free Replacement Limited Warranty</p>
      <div *ngIf="vehicle$ | async as vehicle" class="line">
        <div class="small-text">{{ vehicle | vehicle }}</div>
      </div>
    </div>
  </div>

  <div class="divider"></div>

  <div mat-dialog-content class="tall">
    <ng-container *ngFor="let quotes of batteryQuotes$ | async">

      <div class="main-list">
        <div class="row-info main-row">
          <span class="title" i18n>
            Engine:
          </span>
          <span class="value-engine">
            {{ quotes?.vehicle.engine }}
          </span>
        </div>

        <div class="row-info main-row">

            <span class="title" i18n>
              Part #:
            </span>
            <span class="value-part">
              {{ quotes?.partNumber }}
            </span>

            <span class="title custom-margin-left" i18n>
              CCA:
            </span>
            <span class="value-cca">
              {{ quotes?.coldCrankingAmps }}
            </span>
        </div>

        <div class="sub-list">
          <div class="row-info">
            <div class="title" i18n>
              Member Price:
            </div>
            <span class="value-price-member">
              {{ quotes?.memberPrice && quotes?.memberPrice !== 'N/A' ? (quotes?.memberPrice  | currency) : 'N/A' }} *
            </span>
          </div>
          <div class="row-info">
            <span class="title" i18n>
            Your Member Savings:
            </span>
            <span class="value-savings">
              {{ quotes?.memberPrice && quotes?.memberPrice !== 'N/A' ? ((quotes?.nonMemberPrice - quotes?.memberPrice) | currency) : 'N/A' }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="disclaimer">
      <p i18n>
        *Price does not include sales/EPA taxes and possible labor charges. Inventory availability varies, if your battery is unavailable a battery test and jump start or tow will be provided.
      </p>
    </div>
  </div>
  <button mat-dialog-close class="mat-dialog-close" aria-label="dismiss this message" i18n-aria-label>
    <icon-close></icon-close>
  </button>
</div>
