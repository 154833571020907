export const BATTERY_QUOTES_FIXTURE = {
  '2007-Honda-Accord-30303': [
    {
      vehicle: {
        year: 2007,
        make: 'Honda',
        model: 'Accord',
        engine: 'L4 2.4L ',
      },
      partNumber: '51R-C',
      memberPrice: '124.23',
      nonMemberPrice: '149.23',
      coldCrankingAmps: '450',
      difficult: 'False',
      batteryDisplay: '450 CCA Group Size 51',
    },
    {
      vehicle: {
        year: 2007,
        make: 'Honda',
        model: 'Accord',
        engine: 'V6 3.0L ',
      },
      partNumber: '35N-C',
      memberPrice: '138.86',
      nonMemberPrice: '163.86',
      coldCrankingAmps: '640',
      difficult: 'False',
      batteryDisplay: '640 CCA Group Size 35',
    },
  ]
}
