<ion-row>
  <ng-container *ngIf="dcFast > 0">
    <app-charging-level
      i18n-title
      i18n-description
      title="DC Fast"
      description="40 miles of range for every 10 minutes of charging."
      [volts]="480"
      [numberOfPorts]="dcFast"
    ></app-charging-level>
  </ng-container>

  <ng-container *ngIf="level2 > 0">
    <app-charging-level
      i18n-title
      i18n-description
      title="AC Level 2"
      description="Typically around 26 miles of range per hour of charging."
      [volts]="240"
      [numberOfPorts]="level2"
    ></app-charging-level>
  </ng-container>

  <ng-container *ngIf="level1 > 0">
    <app-charging-level
      i18n-title
      i18n-description
      title="AC Level 1"
      description="4.5 miles of range per hour of charging, or about 22 hours for a full charge."
      [volts]="120"
      [numberOfPorts]="level1"
    ></app-charging-level>
  </ng-container>
</ion-row>
