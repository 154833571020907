<ng-container [ngSwitch]="(step$ | async)">
  <ng-container *ngSwitchCase="'years'">
    <app-create-vehicle-step
      [isCreatingNew]="!(editYear$ | async)"
      [isMember]="isSecure$ | async"
    />
  </ng-container>
  <ng-container *ngSwitchCase="'makes'">
    <app-makes-step class="ion-page" />
  </ng-container>
  <ng-container *ngSwitchCase="'models'">
    <app-models-step class="ion-page" />
  </ng-container>
  <ng-container *ngSwitchCase="'details'">
    <app-vehicle-details-step class="ion-page" />
  </ng-container>
  <ng-container *ngSwitchCase="'plate'">
    <!-- FIXME: Plate to VIN not supported in the initial version of the redesign -->
    <app-add-plate class="ion-page" />
  </ng-container>
  <ng-container *ngSwitchCase="'confirm'">
    <!-- FIXME: Plate to VIN not supported in the initial version of the redesign -->
    <app-vehicle-confirm class="ion-page" />
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="(isPlateToVin$ | async) && !(memberVehicles$ | async).length; else defaultVehicle">
      <!-- FIXME: Plate to VIN not supported in the initial version of the redesign -->
      <app-add-plate class="ion-page" />
    </ng-container>
    <ng-template #defaultVehicle>
      <app-select-vehicle-step class="ion-page" />
    </ng-template>
  </ng-container>
</ng-container>
