import { array, boolean, date, number, object, string } from 'yup'
import { Address, AddressState, Country, OperatingDay, OperatingDayV2, Phone, Shift, } from '../../../shared/types'
import {
  AARData,
  AARVehicle,
  ChargeLevel,
  EvStationDetails,
  RatingSummary,
  Service,
  TowDestination,
  TowDestinationCoordinates,
  TowDestinationDetails,
  TowDestinationResponse
} from './aar.types'

const CountrySchema = object<Country>().shape({
  value: string(),
  code: string(),
})

const AddressStateSchema = object<AddressState>().shape({
  code: string(),
})

const RatingSummarySchema = object<RatingSummary>().shape({
  businessId: string().nullable(),
  reviewCount: number().nullable(),
  ratingAvg: number().nullable(),
  recommendPct: number().nullable(),
})

const ChargeLevelSchema = object<ChargeLevel>().shape({
  dcFast: number(),
  level1: number(),
  level2: number(),
})

const EvStationDetailsSchema = object<EvStationDetails>().shape({
  chargeLevel: ChargeLevelSchema,
  connectorTypes: array().of(string()).nullable(),
  lastUpdated: date().nullable(),
  dateLastConfirmed: date().nullable(),
})

const AddressSchema = object<Address>().shape({
  addressLine: string(),
  cityName: string(),
  stateProv: AddressStateSchema,
  postalCode: string(),
  countryName: CountrySchema,
  type: string(),
})

const PhoneSchema = object<Phone>().shape({
  value: string().nullable(),
  type: string().nullable(),
})

const OperatingDaySchema = object<OperatingDay>().shape({
  day: string(),
  open: string(),
  close: string(),
})

const ShiftSchema = object<Shift>().shape({
  open: string().nullable(),
  close: string().nullable(),
})

const OperatingDaySchemaV2 = object<OperatingDayV2>().shape({
  day: string().nullable(),
  shifts: array().of(ShiftSchema).nullable(),
})

const ServiceVehicleSchema = object<AARVehicle>().shape({
  type: string(),
  value: string(),
})

const ShopServiceSchema = object<AARVehicle>().shape({
  type: string(),
  value: string(),
})

export const AARDataSchema = object<AARData>().shape({
  id: number(),
  type: string(),
  name: string().required(),
  serviceProviderCode: string(),
  latitude: number().required(),
  longitude: number().required(),
  distanceTo: number(),
  address: AddressSchema.required(),
  phone: PhoneSchema,
  emailAddress: string(),
  webAddress: string(),
  operatingDays: array().of(OperatingDaySchema),
  serviceOffers: array().of(string()),
  vehicles: array().of(ServiceVehicleSchema),
  services: array().of(ShopServiceSchema),
  ratingSummary: RatingSummarySchema,
  evStationDetails: EvStationDetailsSchema,
})

export const AARSchema = object({
  aars: array().of(AARDataSchema).defined(),
})

const CoordinatesSchema = object<TowDestinationCoordinates>().shape({
  latitude: number().required(),
  longitude: number().required(),
})

const ServiceSchema = object<Service>().shape({
  code: string().nullable(),
  text: string().nullable(),
})

const TowDestinationDetailsSchema = object<TowDestinationDetails>().shape({
  id: string().required(),
  name: string().required(),
  isDiscountAvailable: boolean().required(),
  priority: boolean().required(),
  serviceProviderCode: string().required(),
  country: string().nullable(),
  ratingSummary: RatingSummarySchema.nullable(),
  operatingDays: array().of(OperatingDaySchemaV2).nullable(),
  city: string().nullable(),
  webAddress: string().nullable(),
  postalCode: string().nullable(),
  makes: array().of(string()).nullable(),
  phones:  array().of(PhoneSchema).nullable(),
  services: array().of(ServiceSchema).nullable(),
  streetAddress: string().nullable(),
  club: string().nullable(),
  state: string().nullable(),
  email: string().nullable(),
})

const TowDestinationSchema = object<TowDestination>().shape({
  towDestinationType: string().required(),
  distance: number().required(),
  coordinates: CoordinatesSchema.required(),
  details: TowDestinationDetailsSchema.required(),
})

export const TowDestinationResponseSchema = object<TowDestinationResponse>().shape({
  result: array().of(TowDestinationSchema).defined(),
})
