<div class="bg-lightgrey">
  <h3 i18n class="large-text">Electric Vehicle Charging</h3>

  <ng-container *ngIf="evStationDetails.chargeLevel">
    <h3 i18n="@@ev-charge-type" class="normal-text color-darkgrey">Type</h3>
    <app-charging-level-list [dcFast]="evStationDetails.chargeLevel.dcFast"
      [level2]="evStationDetails.chargeLevel.level2"
      [level1]="evStationDetails.chargeLevel.level1">
    </app-charging-level-list>
  </ng-container>

  <ng-container *ngIf="evStationDetails.connectorTypes">
    <h3 i18n class="normal-text color-darkgrey">Connectors</h3>
    <app-ev-station-connector-type-list [connectorTypes]="evStationDetails.connectorTypes">
    </app-ev-station-connector-type-list>
  </ng-container>

  <h3 i18n class="normal-text color-darkgrey mt-20">Station Notes</h3>
  <app-ev-station-notes [lastConfirmedDate]="evStationDetails.lastUpdated"
    [operationDayNotes]="evStationDetails?.stationNotes"></app-ev-station-notes>
</div>
