import { AARData, AARRequestParams, } from './aar.types'
import { DestinationType, IndexedCollection, PayloadedAction, } from '../../../shared/types'
import {
  AAR_LOAD,
  AAR_PREVIEW,
  AAR_RESET,
  INCREMENT_AARS_SHOWN,
  NO_MAKE_FACILITIES,
  RESET_TOW_LOCATION_SEARCH,
  SEARCH_AREA,
  SET_DESTINATION_TYPE,
  SET_HAS_RATING_SUMMARY,
  SET_SORTING_TYPE,
  SET_SUGGESTED_SHOP,
  SHOW_ALL_AARS,
  SHOW_OPEN_AARS_ONLY,
} from './aar.actions'
import { CANCEL_EDITING_REQUEST } from '../../../shared/actions/shared.actions'
import { AAR_SORTING_ORDER, AAR_SORTING_TYPES, MapState, } from '../location.types'

export const TOW_DESTINATION_LIMIT = 10
const RESULT_DISPLAY_BATCH_COUNT = 10

export interface AARState {
  data: IndexedCollection<AARData>
  maximumAarsShown: number
  aarParams?: AARRequestParams
  openOnly: boolean
  aarPreviewId: number
  noMakeFacilities: boolean
  destinationType?: DestinationType
  sortingType: AAR_SORTING_TYPES
  sortingOrder: AAR_SORTING_ORDER
  hasRatingsSummary: boolean
  towLocationSearch?: MapState
  suggestedShop?: AARData
}

export const initialState: AARState = {
  data: {},
  maximumAarsShown: RESULT_DISPLAY_BATCH_COUNT,
  openOnly: false,
  aarPreviewId: null,
  noMakeFacilities: false,
  destinationType: null,
  sortingType: AAR_SORTING_TYPES.DEFAULT,
  sortingOrder: AAR_SORTING_ORDER.DEFAULT,
  hasRatingsSummary: false,
  towLocationSearch: null,
  suggestedShop: null,
}

export function reducer(
  state: AARState | undefined = initialState,
  action: PayloadedAction
): AARState {
  switch (action.type) {
    case AAR_LOAD.REQUEST:
      return {
        ...state,
        aarParams: action.payload,
        noMakeFacilities: false,
      }
    case AAR_LOAD.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
        maximumAarsShown: RESULT_DISPLAY_BATCH_COUNT,
      }
    case INCREMENT_AARS_SHOWN:
      return {
        ...state,
        maximumAarsShown: state.maximumAarsShown + RESULT_DISPLAY_BATCH_COUNT,
      }
    case SHOW_ALL_AARS:
      return {
        ...state,
        openOnly: false,
      }
    case SHOW_OPEN_AARS_ONLY:
      return {
        ...state,
        openOnly: true,
      }
    case AAR_PREVIEW: {
      return {
        ...state,
        aarPreviewId: action.payload.id,
      }
    }
    case AAR_RESET:
      return {
        ...initialState,
        destinationType: state.destinationType,
        suggestedShop: state.suggestedShop,
      }
    case CANCEL_EDITING_REQUEST: {
      return {
        ...initialState,
      }
    }
    case NO_MAKE_FACILITIES:
      return {
        ...initialState,
        aarParams: state.aarParams,
        noMakeFacilities: action.payload.active,
      }
    case SET_DESTINATION_TYPE:
      return {
        ...state,
        destinationType: action.payload,
      }
    case SET_SORTING_TYPE:
      return {
        ...state,
        sortingType: action.payload.type,
        sortingOrder: action.payload.order,
      }
    case SEARCH_AREA.REQUEST:
      return {
        ...state,
        towLocationSearch: action.payload,
      }
    case RESET_TOW_LOCATION_SEARCH:
      return {
        ...state,
        towLocationSearch: null,
      }
    case SET_HAS_RATING_SUMMARY:
      return {
        ...state,
        hasRatingsSummary: action.payload,
      }
    case SET_SUGGESTED_SHOP:
      return {
        ...state,
        suggestedShop: action.payload,
      }
    default:
      return state
  }
}
