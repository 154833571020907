import { AbstractResponsiveComponent } from '../../shared/abstract-responsive.component'
import { AbstractComponent } from '../../shared/abstract.component'
import { AbstractIconComponent } from '../../shared/icons/abstract.icon'
import { AgentPanelComponent } from './agent-panel/agent-panel.component'
import { AutocompleteComponent } from './autocomplete/auto-complete.component'
import { CallAaaButtonComponent } from './call-aaa-button/call-aaa-button.component'
import { CustomMarkerComponent } from './custom-marker/custom-marker.component'
import {
  AdvisoryDialogComponent,
  CantRememberIdDialogComponent,
  CloseRequestDialogComponent,
  ConfirmMessageDialogComponent,
  CustomMessageDialogComponent,
  GenericErrorDialogComponent,
  LocationServicesRequiredComponent,
  MemberNoMatchesDialogComponent,
  NotAMemberDialogComponent,
  NotifyPoliceDialogComponent,
  ShowBatteryQuotesDialogComponent
} from './dialog'
import { DialogInvokerComponent } from './dialog/dialog-invoker.component'
import { InvalidMembershipComponent } from './dialog/errors/invalid-membership.component'
import { MembershipIssuesDialogComponent } from './dialog/errors/membership-issues-dialog.component'
import { CallCancelDialogComponent } from './dialog/message/call-cancel.component'
import { CallCompletedDialogComponent } from './dialog/message/call-completed.component'
import { CallDuplicateDialogComponent } from './dialog/message/call-duplicate-dialog.component'
import { SameAddressTowBlDialogComponent } from './dialog/message/same-address-tow-bl-dialog.component'
import { SessionTimeoutDialogComponent } from './dialog/message/session-timeout-dialog.component'
import { TowBoundaryMessageDialogComponent } from './dialog/message/tow-boundary-message-dialog.component'
import { RapTowMileageMessageDialogComponent } from './dialog/message/rap-tow-mileage-message-dialog.component'
import { TowDistanceWarningDialogComponent } from './dialog/message/tow-distance-warning-dialog.component'
import {
  TowLocationAddressWarningDialogComponent
} from './dialog/message/tow-location-address-warning-dialog.component'
import { AuthAdditionalDataComponent } from './dialog/prompts/auth-additional-info/auth-additional-info.component'
import { BatteryQuotesDialogComponent } from './dialog/prompts/battery-quotes-dialog.component'
import { IframeDialogComponent } from './dialog/prompts/iframe-dialog.component'
import {
  LocationAdditionalInfoComponent
} from './dialog/prompts/location-additional-info/location-additional-info.component'
import { LoadingComponent } from './loading/loading.component'
import { NotesInputComponent } from './notes-input/notes-input.component'
import { OptionSelectorComponent } from './option-selector/option-selector.component'
import { SafehtmlComponent } from './safehtml/safehtml.component'
import {
  ServiceHistoryItemComponent
} from './service-history-panel/service-history-item/service-history-item.component'
import { ServiceHistoryPanelComponent } from './service-history-panel/service-history-panel.component'
import { VinIssuesDialogComponent } from './dialog/errors/vin-issues-dialog.component'
import { VasIssuesDialogComponent } from './dialog/errors/vas-issues-dialog.component'
import { RentalIssuesDialogComponent } from './dialog/errors/rental-issues-dialog.component'
import { RapNotEligibleDialogComponent } from './dialog/errors/rap-not-eligible-dialog.component'
import { CallRequestIssueDialogComponent } from './dialog/errors/call-request-issue-dialog.component'
import { CustomPhoneMessageDialogComponent } from './dialog/message/custom-phone-message-dialog.component'
import { GpsDistanceWarningDialogComponent } from './dialog/message/gps-distance-warning-dialog.component'
import {
  AuthAdditionalInfoSearchComponent
} from './dialog/prompts/auth-additional-info-search/auth-additional-info-search.component'
import { SpecialAssistanceDialogComponent } from './dialog/prompts/special-assistance/special-assistance.component'
import { GpsStatusIconComponent } from './gps-status-icon/gps-status-icon.component'
import { PartialCallCancelDialogComponent } from './dialog/message/partial-call-cancel.component'
import { AuthPhoneNumberComponent } from './dialog/prompts/auth-phone-number/auth-phone-number.component'
import { CancelFeedbackDialogComponent } from './dialog/prompts/cancel-feedback/cancel-feedback.component'
import { CancelFeedbackOptionComponent } from './dialog/errors/cancel-feedback-option.component'
import { I18nSwitcherComponent } from './dialog/prompts/i18n-switcher/i18n-switcher.component'
import { LocationSearchComponent } from '../breakdown-location/components/location-search/location-search.component';
import { RatingComponent } from './rating/rating.component'
import { DestinationCardTemplateComponent } from './destination-card-template/destination-card-template.component';

export function UiComponents() {
  return [
    AbstractResponsiveComponent,
    AbstractComponent,
    AbstractIconComponent,
    DialogInvokerComponent,
    LoadingComponent,
    SafehtmlComponent,
    GenericErrorDialogComponent,
    MembershipIssuesDialogComponent,
    VinIssuesDialogComponent,
    VasIssuesDialogComponent,
    RentalIssuesDialogComponent,
    CustomMessageDialogComponent,
    CustomPhoneMessageDialogComponent,
    ConfirmMessageDialogComponent,
    CloseRequestDialogComponent,
    CantRememberIdDialogComponent,
    NotifyPoliceDialogComponent,
    LocationServicesRequiredComponent,
    NotAMemberDialogComponent,
    MemberNoMatchesDialogComponent,
    NotesInputComponent,
    AutocompleteComponent,
    LocationSearchComponent,
    AuthAdditionalDataComponent,
    AuthAdditionalInfoSearchComponent,
    AuthPhoneNumberComponent,
    LocationAdditionalInfoComponent,
    SpecialAssistanceDialogComponent,
    OptionSelectorComponent,
    CallCancelDialogComponent,
    PartialCallCancelDialogComponent,
    CallCompletedDialogComponent,
    CallDuplicateDialogComponent,
    TowDistanceWarningDialogComponent,
    TowLocationAddressWarningDialogComponent,
    DestinationCardTemplateComponent,
    BatteryQuotesDialogComponent,
    ShowBatteryQuotesDialogComponent,
    CustomMarkerComponent,
    CallAaaButtonComponent,
    TowBoundaryMessageDialogComponent,
    RapTowMileageMessageDialogComponent,
    IframeDialogComponent,
    SessionTimeoutDialogComponent,
    SameAddressTowBlDialogComponent,
    InvalidMembershipComponent,
    ServiceHistoryPanelComponent,
    ServiceHistoryItemComponent,
    AgentPanelComponent,
    AdvisoryDialogComponent,
    RapNotEligibleDialogComponent,
    CallRequestIssueDialogComponent,
    GpsDistanceWarningDialogComponent,
    GpsStatusIconComponent,
    RatingComponent,
    CancelFeedbackDialogComponent,
    CancelFeedbackOptionComponent,
    I18nSwitcherComponent
  ]
}

export function EntryModalComponents() {
  return [
    GenericErrorDialogComponent,
    MembershipIssuesDialogComponent,
    VinIssuesDialogComponent,
    VasIssuesDialogComponent,
    RentalIssuesDialogComponent,
    CustomMessageDialogComponent,
    CustomPhoneMessageDialogComponent,
    ConfirmMessageDialogComponent,
    CloseRequestDialogComponent,
    DialogInvokerComponent,
    CantRememberIdDialogComponent,
    NotifyPoliceDialogComponent,
    LocationServicesRequiredComponent,
    NotAMemberDialogComponent,
    MemberNoMatchesDialogComponent,
    AuthAdditionalDataComponent,
    AuthAdditionalInfoSearchComponent,
    AuthPhoneNumberComponent,
    BatteryQuotesDialogComponent,
    ShowBatteryQuotesDialogComponent,
    TowBoundaryMessageDialogComponent,
    RapTowMileageMessageDialogComponent,
    IframeDialogComponent,
    RapNotEligibleDialogComponent,
    CallRequestIssueDialogComponent,
    CancelFeedbackDialogComponent,
    CancelFeedbackOptionComponent,
  ]
}
