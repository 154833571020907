<aaa-modal
  contentMode="scroll"
  [isOpen]="isOpen"
  [initialBreakpoint]="1"
  (aaaModalDidDismiss)="handleCloseModal()"
  [breakpoints]="[0, 1]">

  <!-- User Reviews -->
  <ng-container *ngIf="showReviews; else detailsBlock">
    <ion-row class="ion-align-items-center ion-margin-bottom">
      <ion-col size="3">
        <aaa-button variant="secondary" (click)="showReviewsList(false)">
          <aaa-icon name="chevron-back"></aaa-icon>
        </aaa-button>
      </ion-col>
      <ion-col>
        <aaa-text size="title3">{{aarData.name}}</aaa-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-review-list [ratingSummary]="aarData.ratingSummary"></app-review-list>
      </ion-col>
    </ion-row>
  </ng-container>

  <!-- Facility Details -->
  <ng-template #detailsBlock>
    <ion-grid *ngIf="aarData; else loading">
      <ion-row>
        <ion-col>
          <aaa-text weight="bold" size="title3"> {{ aarData.name }}</aaa-text>
        </ion-col>
      </ion-row>

      <ng-container *ngIf="aarData['ratingSummary']?.reviewCount > 0">
        <ion-row class="ion-align-items-baseline">
          <ion-col size="auto" class="margin-right">
            <aaa-text size="footnote" color="medium">{{ aarData.ratingSummary.ratingAvg | number:'1.0-1' }}</aaa-text>
          </ion-col>
          <ion-col size="auto" class="margin-right">
            <app-rating [rate]="aarData.ratingSummary.ratingAvg"></app-rating>
          </ion-col>
          <ion-col>
            <aria-link-button i18n-ariaLabel id="show-reviews-link" ariaLabel="See reviews" role="link" (clickLink)="showReviewsList()">
              <app-reviews-count [numberReviews]="aarData.ratingSummary.reviewCount" color="primary" ></app-reviews-count>
            </aria-link-button>
          </ion-col>
        </ion-row>
      </ng-container>


      <ion-row class="ion-padding-vertical" id="address-details" *ngIf="aarData.address as address">
        <ion-col>
          <aaa-text>{{ address.addressLine }}</aaa-text>
          <aaa-text color="medium">{{ address.cityName }} · {{ address.stateProv.code }} {{ address.postalCode }}</aaa-text>
        </ion-col>
      </ion-row>

      <ion-row class="ion-justify-content-between vertical-align-center">
        <ion-col>
          <ng-container *ngIf="distanceTo; else loadingDistance">
            <aaa-text size="caption" color="medium">
              <app-distance-display [distance]="distanceTo"></app-distance-display>
            </aaa-text>
          </ng-container>
          <ng-template #loadingDistance>
            <ion-spinner></ion-spinner>
          </ng-template>
        </ion-col>
        <ion-col>
          <app-phone-number-link id="phone" class="text-align-right" [phoneNumber]="aarData.phone?.value"></app-phone-number-link>
        </ion-col>
      </ion-row>

      <ng-container *ngIf="aarData['priority']">
        <ion-row>
          <ion-col>
            <aaa-text size="caption" i18n>Immediately Available</aaa-text>
          </ion-col>
        </ion-row>
      </ng-container>

      <ion-row class="ion-padding-top">
        <ion-col>
          <aaa-button appCtaClickEvent="Select this facility" expand="block" id="select-facility" (click)="handleCloseModal()" i18n>Select this facility</aaa-button>
        </ion-col>
      </ion-row>

      <ng-container *ngIf="isRapUser$ | async">
        <ion-row *ngIf="shouldShowFlexTowLimit() || shouldShowStrictTowLimit()" class="ion-padding-top">
          <ion-card id="tow-limit-message">
            <ion-col size="12" *ngIf="shouldShowFlexTowLimit()">
              <aaa-text size="caption" color="danger">{{flexTowLimitMessage}}</aaa-text>
            </ion-col>
            <ion-col size="12" *ngIf="shouldShowStrictTowLimit()">
              <aaa-text size="caption" color="danger">{{strictTowLimitMessage}}</aaa-text>
              <aaa-text size="caption" color="danger" i18n>
                Please contact us at
                <a class="primary-color" href="tel:{{contactPhone$ | async}}">{{contactPhone$ | async}}</a>
                so that we may assist you.</aaa-text>
            </ion-col>
          </ion-card>
        </ion-row>
      </ng-container>

      <ng-container *ngIf="aarData.isDiscountAvailable && !(isRapUser$ | async) && !(isEvStation$ | async)">
        <ion-row class="ion-padding-top">
          <ion-col>
            <ion-card id="discount-awards">
              <app-img i18n-alt src="assets/drrweb-lib/images/discount-awards.svg" alt="AAA Members discount awards"></app-img>
              <aaa-text weight="bold" class="ion-padding-vertical" color="dark" i18n>This Facility Offers AAA Members:</aaa-text>
              <aaa-text size="caption" color="dark" i18n>AAA Members receive a 10% labor discount on repairs performed at this facility. The maximum labor discount is $75.</aaa-text>
            </ion-card>
          </ion-col>
        </ion-row>
      </ng-container>

      <!-- Electric Vehicle Charging -->
       <ng-container  *ngIf="aarData.evStationDetails">
        <ion-row class="ion-padding-top">
          <ion-col>
            <ion-card>
              <app-electric-vehicle [evStationDetails]="aarData.evStationDetails"></app-electric-vehicle>
            </ion-card>
          </ion-col>
        </ion-row>
      </ng-container>

      <ng-container *ngIf="aarData.serviceOffers.length">
        <ion-row class="ion-padding-top" id="repair-services">
          <ion-col>
            <ion-card>
              <aaa-text weight="bold" color="primary" i18n>Repair Services</aaa-text>
                <ul class="ion-padding-top services-list">
                  <aaa-text color="dark" size="caption">
                    <li class="margin-bottom" *ngFor="let service of aarData.serviceOffers">{{ service | titlecase }}</li>
                  </aaa-text>
                </ul>
            </ion-card>
          </ion-col>
        </ion-row>
      </ng-container>

      <ng-container *ngIf="aarData.vehicles.length">
        <ion-row class="ion-padding-top" id="vehicle-serviced">
          <ion-col>
            <ion-card>
              <aaa-text weight="bold" color="primary">Vehicle Serviced</aaa-text>
              <ul class="ion-padding-top vehicles-list">
                <aaa-text color="dark" size="caption">
                  <li class="margin-bottom" *ngFor="let vehicle of aarData.vehicles"> {{ vehicle.value | titlecase }}</li>
                </aaa-text>
              </ul>
            </ion-card>
          </ion-col>
        </ion-row>
      </ng-container>

      <ng-container *ngIf="!(isEvStation$ | async) && aarData.operatingDays && aarData.operatingDays.length">
        <ion-row class="ion-padding-top">
          <ion-col>
            <ion-card>
              <aaa-text class="ion-padding-bottom" weight="bold" color="primary" i18n>Hours</aaa-text>
                <ion-row class="margin-bottom" *ngFor="let day of compressedHours">
                  <ion-col size="6">
                    <aaa-text size="caption" color="dark">{{ day.days | weekdayI18n }}</aaa-text>
                  </ion-col>
                  <ion-col size="6" class="text-align-right">
                    <aaa-text size="caption" color="dark">{{ day.hours }}</aaa-text>
                  </ion-col>
                </ion-row>
            </ion-card>
          </ion-col>
        </ion-row>
      </ng-container>

    </ion-grid>
  </ng-template>
</aaa-modal>

<ng-template #loading>
  <ion-row class="ion-padding ion-justify-content-center align-content-center">
    <ion-col size="auto">
      <ion-spinner></ion-spinner>
    </ion-col>
  </ion-row>
</ng-template>
