import { object, string, array, number } from 'yup'
import { BatteryQuotesResponse, BatteryQuote, VehicleQuotes } from './quotes.types'

export const VehicleQuoteSchema = object<VehicleQuotes>().shape({
  year: number(),
  make: string(),
  model: string(),
  engine: string(),
})

export const BatteryQuoteSchema = object<BatteryQuote>().shape({
  vehicle: VehicleQuoteSchema.required(),
  batteryDisplay: string(),
  memberPrice: string().nullable(),
  nonMemberPrice: string().nullable(),
  coldCrankingAmps: string(),
  partNumber: string(),
  difficult: string(),
})

export const BatteryQuotesResponseSchema = object<BatteryQuotesResponse>().shape({
  batteryQuotes: array().of(BatteryQuoteSchema)
})
